import React, { createRef, useState, useEffect } from "react"
import HeroSection from "../hero-section"
import ArrowSubmit from "../images/arrow-submit"
import { toCurrency } from "../../helpers"
import { useBuilderContext, useQualifyContext } from "../../context"
import { Type } from "../../state"
import ArrowScroll from "../images/arrow-scroll"

const SelectedOption = ({ swatch }) => (
  <div className="text-gray-500 text-sm md:text-lg pt-2 md:pt-0">
    <span>
      {swatch.title}
      {swatch.cost ? `: $${toCurrency(swatch.cost)}` : null}
    </span>
  </div>
)

export default function BuilderPdf({ modal, onGoBack, product }) {

  //const upgrades = [
  //  ...modal.upgrade,
  //  ...modal.upgrades.exterior,
  //  ...modal.upgrades.interior,
  //]

  var request_id = sessionStorage.getItem("builder_request_id"); // retrieve variable

  return (
    <>
      <div id = "divToPrint">
        <section className="relative flex flex-col  ">
          <div className="flex flex-row justify-center items-center py-18">

           FINAL ESTIMATE
           
          </div>
        </section>
      </div>
    </>
  )
}