import React from "react"
import { navigate } from "gatsby"
import axios from "axios"

import QualifyStep from "./qualify-step"
import { useQualifyContext } from "../../context"
import { Type, Field, Status } from "../../state"
import LoadingSpinner from "../loading-spinner"

export default function QualifyEnd() {
  const [state, dispatch] = useQualifyContext()

  const getPdf = async () => {
    dispatch({ payload: { [Field.Status]: Status.Loading } })

    try {
      const res = await axios.get(
        `https://us-central1-modal-living.cloudfunctions.net/createPdf?id=${state.documentId}`,
        {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/pdf",
          },
        }
      )

      const blob = new Blob([res.data], { type: "application/pdf" })
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      link.download = "live-modal.pdf"
      link.click()
      dispatch({ payload: { [Field.Status]: Status.Idle } })
    } catch (e) {
      console.warn(e)
      dispatch({ payload: { [Field.Status]: Status.Error } })
    }
  }

  return (
    <QualifyStep>
      <div className="text-center">
        <h2 className="font-light tracking-wide mx-6">
          <span className="font-bradford">Thank You!</span>
        </h2>
        <h4 className="font-bradford text-gray-500 mt-4">
          {state.status === Status.Loading
            ? "Creating your pdf..."
            : "Our team will reach out shortly to provide a free property analysis."}
        </h4>
      </div>

      <button
        className="relative bg-green-900 text-white text-center flex justify-center items-center px-8 py-3 mx-4 mt-8 shadow-xl hover:underline"
        onClick={getPdf}
      >
        {state.status === Status.Loading ? (
          <LoadingSpinner fill="white"></LoadingSpinner>
        ) : (
          "Download Your Pdf"
        )}
      </button>

      <button
        className="relative w-40 text-green-900 text-center flex justify-center items-center px-8 py-3 mx-4 mt-8 hover:underline"
        onClick={() => navigate("/")}
      >
        Back to Site
      </button>
    </QualifyStep>
  )
}
