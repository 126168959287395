import React, { useMemo } from "react"

import { useBuilderContext } from "../../context/builder-provider"
import BuilderSidebar from "./builder-sidebar"

export default function BuilderSidebarContainer() {
  const [state] = useBuilderContext()
  const { product, swatches } = state.options

  const sortSwatchesByCategory = (swatchArray = []) => {
    return swatchArray.reduce((sorted, swatch) => {
      const isCategory = !!sorted[swatch.category]

      if (isCategory) {
        sorted[swatch.category].push(swatch)
      } else {
        sorted[swatch.category] = [swatch]
      }

      return sorted
    }, {})
  }

  const sortedSwatches = useMemo(() => {
    return {
      ...sortSwatchesByCategory(swatches.interior),
      exterior: swatches.exterior,
      upgrade: swatches.upgrade,
    }
  }, [state.options])

  return <BuilderSidebar product={product} swatches={sortedSwatches} />
}
