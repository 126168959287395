import React, { useEffect, useState } from "react"

import { useQualifyContext } from "../../context/qualify-provider"
import { getProducts } from "../../services/firestore-service"
import { BuilderProvider } from "../../context/builder-provider"
import BuilderSidebar from "../builder/builder-sidebar-container"
import BuilderMain from "../builder/builder-main"
import { Type, Field } from "../../state"


import "../builder/builder-sidebar2.css"

export default function QualifyBuilder() {
  const [state] = useQualifyContext()
  const [product, setProduct] = useState(null)

  useEffect(() => {

    //alert("QUALIFY-BUILDER")

    const getProduct = async () => {
      const res = await getProducts()
      const modalParts = state.modal.split("-")
      const modal = `Modal ${modalParts[1]}`
      const product = res.find(p => p.title === modal)

      //alert(JSON.stringify(product))

      setProduct(product)
    }

    getProduct()
  }, [state.modal])

  if (!product) {
    return null
  }

  //const [_, dispatch] = useQualifyContext()

  const [_, dispatch] = useQualifyContext()
  //SKIP THIS STEP
  //dispatch({
   //type: Type.NextStep,
  //})

  //var test = sessionStorage.getItem('builder_selected_modal');
  //document.getElementById("test123").innerText = "HERE" + test;

  return (
    <BuilderProvider product={product} selected={state.builder}>

      <section className="relative flex flex-wrap w-full">


      {/*
          <div>
            <a class="quote btn btn-lg btn-warning" href="#quote_modal" title="">Ask for a quote!</a>
          </div>
          

          <div id="quote_modal" class="QuoteModal">
            <div class="popup_modal">
              <div>
                <a href="#close" title="Close" class="quoteclose">X</a>
                <h3 class="text-center">Ask for a Quote</h3>
              </div>
              <div>
                <form role="form" class="text-center">
                 HERE
                </form>
              </div>
            </div>
          </div>

      */}


        <div className="sticky top-0 lg:w-screen-74 lg:h-screen z-20 bg-gray-100">
          <BuilderMain></BuilderMain>
        </div>
       
        <div className="lg:w-screen-26 h-screen w-full overflow-y-scroll pt-80 md:pt-125 lg:pt-0 absolute md:right-0">
          <BuilderSidebar product={product} selected={state.builder}></BuilderSidebar>
        </div>
      </section>
    </BuilderProvider>
  )
}
