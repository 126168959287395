import React, { useLayoutEffect, useRef, useState, useEffect } from "react"
import BuilderExpandingListItem from "./builder-list-item"
import BuilderCost from "./builder-cost"
import Checkmark from "../images/checkmark"
import { toCurrency } from "../../helpers"
import { useQualifyContext } from "../../context/qualify-provider"

//import { Map, Marker, GoogleApiWrapper, useGoogleMap } from 'google-maps-react';


export default function BuilderSidebar({ product, swatches }) {

  

const isActive = '';

function sliderRange(parameter, min, max){

  document.getElementById(parameter + "-input").min=min;
  document.getElementById(parameter + "-input").max=max;

  var elem = document.getElementById(parameter + "-input");
  var rangeValue = function(){

      var newValue = elem.value;

        if (parameter == "panels-count") {

          document.getElementById(parameter + "-selector").innerHTML = newValue;
          sessionStorage.setItem(parameter + "-selector", newValue);

          }
      
  }

  elem.addEventListener("input", rangeValue);

}

function generateSatellitePic(requested_address) {
  var data = new FormData();
  data.append("address", requested_address);
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "https://api.checksolarcost.com/v1/generateSatellitePic");
  xhr.send(data);
  xhr.onreadystatechange = function() {
    if (xhr.readyState === XMLHttpRequest.DONE) {

      var response_wquotes = xhr.responseText;
      var response = response_wquotes.replace(/['"]/g, '');
      var satelite_image_url = "https://api.checksolarcost.com/v1/getSatelliteImageFile/" + response + ".png"
      //alert (satelite_image_url)

      try {
      document.getElementById("satellite_image").src = satelite_image_url;
      } catch {}

    }
  }
}

function saveRoofPanels(requested_address) {
  var data = new FormData();
  data.append("address", requested_address);
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "https://api.checksolarcost.com/v1/saveRoofPanels");
  xhr.send(data);
  xhr.onreadystatechange = function() {
    if (xhr.readyState === XMLHttpRequest.DONE) {

      var response_wquotes = xhr.responseText;
      var response = response_wquotes.replace(/['"]/g, '');

      //alert(response)
      var satelite_image_url = "https://api.checksolarcost.com/v1/getSatelliteImageFile/" + response + "_combo.png"
      //alert (satelite_image_url)

      try {
      document.getElementById("satellite_image").src = satelite_image_url;
      } catch {}

    }
  }
}

useEffect(() => {

  //const urlParams = new URLSearchParams(window.location.search);
  //var requested_address = urlParams.get("address");
  var requested_address = localStorage.getItem('requested_address');

  generateSatellitePic(requested_address)
  saveRoofPanels(requested_address)
  //alert(requested_address)
}, [])


  return (

    <section className="w-full flex flex-col bg-white justify-end font-light">
    
      <ul className="w-full mb-16">

        <div className="cursor-pointer font-light tracking-wide px-4 py-4">


        <img id="satellite_image" src=""></img>

          <div className="gradient-line"></div>
       
            <div className="flex flex-wrap py-3 ">Home address:&nbsp; 
              <div className="font-light " id="requested_address"></div>
            </div>

            <div className="border-b border-gray-400 "></div>
           

            <div className="flex flex-wrap font-light py-3">Number of panels:&nbsp; 
              <div className="" id="panels-count-selector">100</div>
            </div>

          
              <input className="slider"
                id="panels-count-input"
                type="range"
                defaultValue={sessionStorage.getItem('panels-count-selector')}
                step="1"
                min="1"
                max="150"
                onChange={() => sliderRange("panels-count", "1", "150")} //parameter, min, max
                />
          

        </div>

       
        <BuilderExpandingListItem title="Panels" startOpen={true}>

          <ul className="flex flex-wrap items-start list-none py-4 md:justify-start justify-center">

            <li>
              <button
                //onClick={() => handleSelect(swatch)}
                className="flex flex-col items-center text-center m-2 w-32 focus:outline-none"
              >
                <div className="relative flex justify-center items-center w-32 h-32">
                  {isActive ? (
                    <Checkmark className="z-10 text-gray-500" />
                  ) : null}
                  <img
                    className={`absolute inset-0 w-32 h-32 rounded-full builder-swatch ${
                      isActive ? "active-swatch" : ""
                    }`}
                    src="https://richsolar.com/cdn/shop/products/Mega-100-Watt-Solar-Panel2.jpg?v=1669758660&width=675"
                  ></img>
                  
                    <div className="upgrade-badge z-10">
                      ${toCurrency(999)}
                    </div>

                </div>
                <small
                  className={`mt-3 text-gray-500 text-center small-lh ${
                    isActive ? "" : ""
                  }`}
                >
                  {"Maxeon® MAX3-470W-COM"}
                </small>
              </button>
            </li>

            <li>
              <button
                //onClick={() => handleSelect(swatch)}
                className="flex flex-col items-center text-center m-2 w-32 focus:outline-none"
              >
                <div className="relative flex justify-center items-center w-32 h-32">
                  {isActive ? (
                    <Checkmark className="z-10 text-gray-500" />
                  ) : null}
                  <img
                    className={`absolute inset-0 w-32 h-32 rounded-full builder-swatch ${
                      isActive ? "active-swatch" : ""
                    }`}
                    src="https://richsolar.com/cdn/shop/products/Mega-100-Watt-Solar-Panel2.jpg?v=1669758660&width=675"
                  ></img>
                  
                    <div className="upgrade-badge z-10">
                      ${toCurrency(999)}
                    </div>

                </div>
                <small
                  className={`mt-3 text-gray-500 text-center small-lh ${
                    isActive ? "" : ""
                  }`}
                >
                  {"Maxeon® MAX3-470W-COM"}
                </small>
              </button>
            </li>

            <li>
              <button
                //onClick={() => handleSelect(swatch)}
                className="flex flex-col items-center text-center m-2 w-32 focus:outline-none"
              >
                <div className="relative flex justify-center items-center w-32 h-32">
                  {isActive ? (
                    <Checkmark className="z-10 text-gray-500" />
                  ) : null}
                  <img
                    className={`absolute inset-0 w-32 h-32 rounded-full builder-swatch ${
                      isActive ? "active-swatch" : ""
                    }`}
                    src="https://richsolar.com/cdn/shop/products/Mega-100-Watt-Solar-Panel2.jpg?v=1669758660&width=675"
                  ></img>
                  
                    <div className="upgrade-badge z-10">
                      ${toCurrency(999)}
                    </div>

                </div>
                <small
                  className={`mt-3 text-gray-500 text-center small-lh ${
                    isActive ? "" : ""
                  }`}
                >
                  {"Maxeon® MAX3-470W-COM"}
                </small>
              </button>
            </li>

            <li>
              <button
                //onClick={() => handleSelect(swatch)}
                className="flex flex-col items-center text-center m-2 w-32 focus:outline-none"
              >
                <div className="relative flex justify-center items-center w-32 h-32">
                  {isActive ? (
                    <Checkmark className="z-10 text-gray-500" />
                  ) : null}
                  <img
                    className={`absolute inset-0 w-32 h-32 rounded-full builder-swatch ${
                      isActive ? "active-swatch" : ""
                    }`}
                    src="https://richsolar.com/cdn/shop/products/Mega-100-Watt-Solar-Panel2.jpg?v=1669758660&width=675"
                  ></img>
                  
                    <div className="upgrade-badge z-10">
                      ${toCurrency(999)}
                    </div>

                </div>
                <small
                  className={`mt-3 text-gray-500 text-center small-lh ${
                    isActive ? "" : ""
                  }`}
                >
                  {"Maxeon® MAX3-470W-COM"}
                </small>
              </button>
            </li>

          </ul>

        </BuilderExpandingListItem>

        <BuilderExpandingListItem title="Bataries" startOpen={true}>


          <ul className="flex flex-wrap items-start list-none py-4 md:justify-start justify-center">

            <li>
              <button
                //onClick={() => handleSelect(swatch)}
                className="flex flex-col items-center text-center m-2 w-32 focus:outline-none"
              >
                <div className="relative flex justify-center items-center w-32 h-32">
                  {isActive ? (
                    <Checkmark className="z-10 text-gray-500" />
                  ) : null}
                  <img
                    className={`absolute inset-0 w-32 h-32 rounded-full builder-swatch ${
                      isActive ? "active-swatch" : ""
                    }`}
                    src="https://store.solarcellzusa.com/Website%20Images/Item%20Images/Powerwall%202_01.jpg?resizeid=15&resizeh=1200&resizew=1200"
                  ></img>
                  
                    <div className="upgrade-badge z-10">
                      ${toCurrency(8999)}
                    </div>
           
                </div>
                <small
                  className={`mt-3 text-gray-500 text-center small-lh ${
                    isActive ? "" : ""
                  }`}
                >
                  {"Enphase Storage System (3 kWh)"}
                </small>
              </button>
            </li>

            <li>
              <button
                //onClick={() => handleSelect(swatch)}
                className="flex flex-col items-center text-center m-2 w-32 focus:outline-none"
              >
                <div className="relative flex justify-center items-center w-32 h-32">
                  {isActive ? (
                    <Checkmark className="z-10 text-gray-500" />
                  ) : null}
                  <img
                    className={`absolute inset-0 w-32 h-32 rounded-full builder-swatch ${
                      isActive ? "active-swatch" : ""
                    }`}
                    src="https://store.solarcellzusa.com/Website%20Images/Item%20Images/Powerwall%202_01.jpg?resizeid=15&resizeh=1200&resizew=1200"
                  ></img>
                  
                    <div className="upgrade-badge z-10">
                      ${toCurrency(8999)}
                    </div>
           
                </div>
                <small
                  className={`mt-3 text-gray-500 text-center small-lh ${
                    isActive ? "" : ""
                  }`}
                >
                  {"Enphase Storage System (3 kWh)"}
                </small>
              </button>
            </li>


          </ul>

        </BuilderExpandingListItem>

         {/*
        <BuilderExpandingListItem title="Flooring">
          <BuilderSwatchList type="floor" swatches={swatches_new_obj.floor} />
        </BuilderExpandingListItem>
        <BuilderExpandingListItem title="Cabinets">
          <BuilderSwatchList type="cabinet" swatches={swatches_new_obj.cabinet} />
        </BuilderExpandingListItem>
        <BuilderExpandingListItem title="Tile">
          <BuilderSwatchList type="tile" swatches={swatches_new_obj.tile} />
        </BuilderExpandingListItem>
        <BuilderExpandingListItem title="Counters">
          <BuilderSwatchList type="counter" swatches={swatches_new_obj.counter} />
        </BuilderExpandingListItem>
        <BuilderExpandingListItem title="Bathroom Option">
          <BuilderSwatchList type="bathroom" swatches={swatches_new_obj.bathroom} />
        </BuilderExpandingListItem>
        <BuilderExpandingListItem title="Upgrades">
          <BuilderSwatchList type="upgrade" swatches={swatches_new_obj.upgrade} />
        </BuilderExpandingListItem>
        <BuilderExpandingListItem title="Selections Summary" bg="dark">
          <BuilderSelections />
        </BuilderExpandingListItem>

                
        <BuilderExpandingListItem title="Disclaimers" bg="dark">
          <div className="pb-6 text-left small-lh">
            {" "}
            <span className="text-left font-light text-xs text-gray-500">
              {product_new_obj.disclaimer}
            </span>
          </div>
        </BuilderExpandingListItem>

        */}

        <BuilderExpandingListItem startOpen={true} title="Disclamer">
          <div className="pb-6 text-left small-lh font-light text-xs text-gray-500">
            {"Legal statement"}
          </div>
        </BuilderExpandingListItem>

       
      </ul>
      <BuilderCost />
    </section>

  )
}
