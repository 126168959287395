import React, { useState } from "react"
import Collapsible from "react-collapsible"

import TargetIcon from "../images/target-icon"

export default function BuilderExpandingListItem({
  title,
  children,
  startOpen = false,
  bg = "light",
}) {
  const [isOpen, setIsOpen] = useState(startOpen)

  return (
    <div
      className="py-6 cursor-pointer font-light tracking-wide px-6 md:px-8 bg-gray-100"
    >
      <div className="">
        <button
          className="w-full flex justify-between items-start text-left font-light text-4xl py-6 text-gray-600 outline-none focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="text-md">{title}</span>
          <TargetIcon className="ml-4 py-4" rotate={isOpen}></TargetIcon>
        </button>

        <Collapsible 
          trigger={null}
          open={isOpen}
          transitionTime={200}
          transitionCloseTime={150}
          easing={"ease-out"}
        >
          {children}
        </Collapsible>
      </div>
    </div>
  )
}
