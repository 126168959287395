import React, { createRef, useState, useEffect } from "react"

import ArrowSubmit from "../images/arrow-submit"
import { toCurrency } from "../../helpers"
import { useBuilderContext, useQualifyContext } from "../../context"
import { Type } from "../../state"




export default function BuilderCost() {
  const [state] = useBuilderContext()
  const [_, qualifyDispatch] = useQualifyContext()
  
    
  
    const submitBuilderConfiguration = () => {
      createBuilderRequest();
      qualifyDispatch({
        type: Type.Builder,
        payload: {
          selected: { ...state.selected },
          variations: { ...state.variations },
          cost: state.cost,
        },
      })
      qualifyDispatch({ type: Type.NextStep })
    }

    
  
    function createBuilderRequest(){

        var uuid = require("uuid");
        var request_id = uuid.v4();
        sessionStorage.setItem("builder_request_id", request_id); // set a variable

        var data = new FormData();
        data.append("request_id", request_id);
        data.append("data", JSON.stringify(state));

        var xhr = new XMLHttpRequest();
        xhr.open("POST", "https://api.checksolarcost.com/v1/createBuilderRequest");
        xhr.send(data);
        xhr.onreadystatechange = function() {
            if (xhr.readyState === XMLHttpRequest.DONE) {
            }
          }
      }

  //CHECK WHAT UNIT SELECTED
  //var builder_selected_unit = sessionStorage.getItem("builder_selected_unit");
  //if (builder_selected_unit == "modal-00") { var product_new = sessionStorage.getItem("00_product"); var swatches_new = sessionStorage.getItem("00_swatches"); var cost_new = sessionStorage.getItem("00_cost"); var old_cost = 104000;}
  //if (builder_selected_unit == "modal-01") { var product_new = sessionStorage.getItem("01_product"); var swatches_new = sessionStorage.getItem("01_swatches"); var cost_new = sessionStorage.getItem("01_cost"); var old_cost = 119000;}
  //if (builder_selected_unit == "modal-02") { var product_new = sessionStorage.getItem("02_product"); var swatches_new = sessionStorage.getItem("02_swatches"); var cost_new = sessionStorage.getItem("02_cost"); var old_cost = 129000;} 

  //var product_new_obj = JSON.parse(product_new);
  //var swatches_new_obj = JSON.parse(swatches_new);
  //var cost_new_obj = JSON.parse(cost_new);


  useEffect(() => {
    //alert("HERE")
  }, [])


  return (
    <button
      // ref={linkRef}
      onClick={() => {submitBuilderConfiguration(); }}
      className={
        "bottom-0 fixed flex flex-row items-center justify-between bg-green-900 h-16 px-6 z-40 w-full lg:w-screen-26 text-white focus:outline-none ${className}"
      }
    >
      <div className="font-light tracking-wide font-bradford font-light">
      ${toCurrency(24443)}
      

      </div>

      <div className="flex fill-current text-white">
        <span className="mr-4 tracking-wide font-light">Generate quote</span>

        <ArrowSubmit></ArrowSubmit>
      </div>
    </button>
  )
}