import React, { createRef, useState, useEffect } from "react"

import QualifyStep from "./qualify-step"
import { useQualifyContext } from "../../context/qualify-provider"
import { Type } from "../../state"

export default function QualifyMessage() {
  const [state, dispatch] = useQualifyContext()

  useEffect(() => {
    //alert("QUALIFY-MESSAGE")
  },[]);

  //SKIP THIS STEP
  dispatch({
    type: Type.NextStep,
  })

  return (
    <></>
  )
}
