import React, { createRef, useState, useEffect } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import BuilderPdf from "../builder/builder-pdf"
import BuilderTimeline from "../builder/builder-timeline"

import { useQualifyContext } from "../../context"
import { Type } from "../../state"
import QualifyCustomer from "./qualify-customer"
import { liveGoogleRecaptchaKey, localGoogleRecaptchaKey } from "../../global"
import BuilderSidebar2 from "../builder/builder-sidebar2"


import "../builder/builder-sidebar2.css"


//////////////////////// ADD WEB EVENT //////////////////////
function addWebEvent(event_type, event_name) {
    
  var data = new FormData();
  data.append("event_type", event_type);
  data.append("event_name", event_name);
  
  const queryString = typeof window !== "undefined" ? window.location.search : ""
  const urlParams = new URLSearchParams(queryString);
  var cid = urlParams.get("cid");
  data.append("cid", cid);
  
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
  xhr.send(data);
  }


  

  






export default function QualifyPreview() {
  const [state, dispatch] = useQualifyContext()
  const modal = {
    ...state.builder.selected,
    variations: {
      ...state.builder.variations,
    },
    cost: state.builder.cost,
  }

  const handleBack = () => dispatch({ type: Type.PreviousStep })

    if (sessionStorage.getItem("is_get_started") == "true") { 
      addWebEvent("visit", "get-started");
      var is_get_started = true; } 
    else { 
      addWebEvent("visit", "design-builder-estimate-step");
      var is_get_started = null; } 

  return (
    <>
    <section className="relative flex flex-wrap w-full ">

    
          <div className="sticky top-0 lg:w-screen-74 lg:h-screen z-20 bg-green-900">
            <BuilderPdf onGoBack={handleBack} modal={modal} />
          </div>
      

          <div className="lg:w-screen-26 h-screen w-full overflow-y-scroll pt-80 lg:pt-0 absolute md:right-0 ">
            <BuilderSidebar2 modal={modal}></BuilderSidebar2>
          </div>

    </section>
  </>
  )
}









