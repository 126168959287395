import React, { createRef, useState, useEffect } from "react"

import SEO from "../../components/seo"
import { QualifyProvider } from "../../context/qualify-provider"
import QualifyStepper from "../../components/qualify/qualify-stepper"

export default function SeeIfYouQualify({ location }) {
  //console.log(location)

  useEffect(() => {
		//alert("DESIGN-BUILDER")
	},[]);
  

  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}
  useEffect(() => {
    //addWebEvent("visit", "design-builder")
  },[]);



  return (
    <QualifyProvider>
      <SEO title="Design" />

      <QualifyStepper
        //skipFirstStep={location.state && !!location.state.formatted_address}
        address={location.state}
      ></QualifyStepper>
    </QualifyProvider>
  )
}
