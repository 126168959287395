import React, { createRef, useState, useEffect } from "react"
import { useQualifyContext } from "../../context/qualify-provider"
import { Type, Field } from "../../state"

export default function QualifyModal() {
  const [_, dispatch] = useQualifyContext()

	useEffect(() => {
		//alert("QUALIFY-MODAL")
	},[]);

	dispatch({
		payload: { [Field.Modal]: "modal-00" },
	})
	dispatch({ type: Type.NextStep })

  return (
    <>
	
    </>

  )
}
