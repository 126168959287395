import React, { createRef, useState, useEffect } from "react"
import { useQualifyContext } from "../../context/qualify-provider"
import { Type, Field } from "../../state"
import QualifyButton from "./qualify-button"
import QualifyStep from "./qualify-step"

export default function QualifyUse() {
  const [_, dispatch] = useQualifyContext()

  useEffect(() => {
          //alert("QUALIFY-USE")
  },[]);

  //SKIP THIS STEP
  dispatch({
    type: Type.NextStep,
  })

  return (
    <></>
  )
}
