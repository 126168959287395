import React, { createRef, useState, useEffect } from "react"

import BuilderExpandingListItem from "./builder-list-item"
import BuilderExpandingListItem2 from "./builder-list-item2"
import BuilderExpandingListItem3 from "./builder-list-item3"
import BuilderExpandingListItem4 from "./builder-list-item4"

import BuilderSwatchList from "./builder-swatch-list"
import BuilderCost from "./builder-cost"
import BuilderSelections from "./builder-selections"
import Checkmark from "../images/checkmark"
import Collapsible from "react-collapsible"
import TargetIcon from "../../components/images/target-icon"
import LoadingSpinner from "../../components/loading-spinner"
import { usePlacesWidget } from "react-google-autocomplete";
import { Link } from "gatsby"
import { Type } from "../../state"
import { useQualifyContext } from "../../context/qualify-provider"
import "./builder-sidebar2.css"
import ArrowSubmit from "../images/arrow-submit"

export default function BuilderSidebar({ product, swatches, modal}) {

//alert(JSON.stringify(modal))

  //const modal = "";

  //const upgrades = [
  //  ...modal.upgrade,
  //  ...modal.upgrades.exterior,
  //  ...modal.upgrades.interior,
  //]

  const SelectedOption = ({ swatch }) => (
    <div className="">
      <span>
        {swatch.title}
        {swatch.cost ? `: +${formatter.format(swatch.cost)}` : null}
      </span>
    </div>
  )

//////////////////////// CHECK WHAT UNIT SELECTED //////////////////////
var builder_selected_unit = sessionStorage.getItem("builder_selected_unit");
if (builder_selected_unit == "modal-00") { var product_new = sessionStorage.getItem("00_product"); var swatches_new = sessionStorage.getItem("00_swatches"); }
if (builder_selected_unit == "modal-01") { var product_new = sessionStorage.getItem("01_product"); var swatches_new = sessionStorage.getItem("01_swatches"); }
if (builder_selected_unit == "modal-02") { var product_new = sessionStorage.getItem("02_product"); var swatches_new = sessionStorage.getItem("02_swatches"); } 

try {
var product_new_obj = JSON.parse(product_new);
var swatches_new_obj = JSON.parse(swatches_new);
} catch (e) {
  location.reload()
}

const [state, dispatch] = useQualifyContext()

function updateUnitSelection(option) {
  sessionStorage.setItem("builder_selected_unit", option);
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  //maximumFractionDigits: 2,
  maximumFractionDigits: 0,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  //formatter.format(max)
});

//////////////////////// WIZAED STEPS LOGIC //////////////////////
function currentWizardStep(step){
  if (step === 1) {
      document.getElementById("wizard-step1").className = "active"
      document.getElementById("wizard-step2").className = ""
      document.getElementById("wizard-step3").className = ""
  }
  if (step === 2) {
      document.getElementById("wizard-step1").className = "active"
      document.getElementById("wizard-step2").className = "active"
      document.getElementById("wizard-step3").className = ""
  }
  if (step === 3) {
      document.getElementById("wizard-step1").className = "active"
      document.getElementById("wizard-step2").className = "active"
      document.getElementById("wizard-step3").className = "active"
  }
}

//////////////////////// SECTION MANIPULATION FUNCTIONS //////////////////////
function unlockPaymentSection(){
  addWebEvent("visit", "design-builder-payment-step");
  document.getElementById("payment-section-locked").hidden = true;
  document.getElementById("payment-section-unlocked").hidden = false;
}

function nextStepButton(value){
  if (value == "enable") {
    document.getElementById("next-step-button-disabled").hidden = true;
    document.getElementById("next-step-button").hidden = false;
  } 
  if (value == "disable") {
    document.getElementById("next-step-button-disabled").hidden = false;
    document.getElementById("next-step-button").hidden = true;
  }
  if (value == "hide") {
    document.getElementById("next-step-button-section").hidden = true;
  }
  if (value == "show") {
    document.getElementById("next-step-button-section").hidden = false;
  }
}

function showPaymentSuccessSection() {
  document.getElementById("payment-section-card-success").hidden = false;
}
function hideCreditCardSection(){
  document.getElementById("credit-card-section").hidden = true;
}
function creditCardDeclineMessage(value) {
    if (value == "show") {
      document.getElementById("payment-section-card-error").hidden = false;
    }
    if (value == "hide") {
      document.getElementById("payment-section-card-error").hidden = true;
    }
  }
function configurationSavedSection(value){
  if (value == "show") {
    document.getElementById("configuration_saved").hidden = false;
  }
  if (value == "hide") {
    document.getElementById("configuration_saved").hidden = true;
  }
}
function estimateSection(value){
  if (value == "show") {
    document.getElementById("estimate_section").hidden = false;
  }
  if (value == "hide") {
    document.getElementById("estimate_section").hidden = true;
  }
}
function nextStepButtonWrongState(value){
  if (value == "show") {
    document.getElementById("next-step-button-section-wrong-state").hidden = false;
  }
  if (value == "hide") {
    document.getElementById("next-step-button-section-wrong-state").hidden = true;
  }
}
function checkEmailForTestingMode(email){
  try {
      if (email.includes("@checksolarcost.com") === true){
        document.getElementById("feasibility_price_production").hidden = true;
        document.getElementById("feasibility_price_testing").hidden = false;
      }
  } catch (error) {}
}
function isStateAllowed(address, reqType, asyncProc) {
  var data = new FormData();
  data.append("address", document.getElementById("address").value);
  var req = new XMLHttpRequest();
  if (asyncProc) { 
    req.onreadystatechange = function() { 
      if (this.readyState == 4) {
        asyncProc(this);
      } 
    };
  }
  req.open(reqType, address, !(!asyncProc));
  req.send(data);
  return req;
}
function waitingListSection(value){
  if (value == "show") {
    document.getElementById("waiting-list-section").hidden = false;
  }
  if (value == "hide") {
    document.getElementById("waiting-list-section").hidden = true;
  }
}
function formSubmittedSuccessSection(value){
  if (value == "show") {
    document.getElementById("form-submitted-success").hidden = false;
  }
  if (value == "hide") {
    document.getElementById("form-submitted-success").hidden = true;
  }
}


//////////////////////// CHECK IF THIS IS LOAD WITH REQUEST_ID IN URL //////////////////////
if (typeof sessionStorage.getItem("builder_request_id_from_url") !== 'undefined' && sessionStorage.getItem("builder_request_id_from_url") !== null) {

  //HIDE CONFIG SECTION
  //useEffect(() => {
    //configurationSavedSection("hide")
  //});

  //alert(sessionStorage.getItem("builder_request_id_from_url"))
 
  //SETUP REQUEST ID CONST FOR PAGE
  var request_id = sessionStorage.getItem("builder_request_id_from_url");

  //CLEAR STORAGE
  sessionStorage.removeItem('builder_request_id_from_url');

  function getFeasibilityStudyUserProfile(address, reqType, asyncProc) {
    var data = new FormData();
    data.append("request_id", request_id);
    var req = new XMLHttpRequest();
    if (asyncProc) { 
      req.onreadystatechange = function() { 
        if (this.readyState == 4) {
          asyncProc(this);
        } 
      };
    }
    req.open(reqType, address, !(!asyncProc));
    req.send(data);
    return req;
    }
  
    var req = getFeasibilityStudyUserProfile("https://api.checksolarcost.com/v1/getFeasibilityStudyUserProfile", "POST");
    var response = req.responseText;
    
    var response_obj = JSON.parse(response);
    var first_name = response_obj.first_name
    var last_name = response_obj.last_name
    var email = response_obj.email
    var phone = response_obj.phone
    var form_type = response_obj.form_type

    if (form_type == "estimate_request") { sessionStorage.setItem("form_type_is_estimate_request", "true"); }
    else {sessionStorage.removeItem('form_type_is_estimate_request');}

    var message = response_obj.message
    var created_at = response_obj.created_at
    var crm_synced_at = response_obj.crm_synced_at
    var user_type = response_obj.user_type
    var ready_tostart = response_obj.ready_tostart
    var financing = response_obj.financing
    var knowledge = response_obj.knowledge
    var address = response_obj.address
    var project_type = response_obj.project_type
    var ip_address = response_obj.ip_address
    var feasibility_submitted_at = response_obj.feasibility_submitted_at
    var payment_made_at = response_obj.payment_made_at

    useEffect(() => {
      if (user_type !== null) {
        document.getElementById(user_type).selected = true;
      }
      if (ready_tostart !== null) {
        document.getElementById(ready_tostart).selected = true;
      }
      if (financing !== null) {
        document.getElementById(financing).selected = true;
      }
      if (project_type !== null) {
        document.getElementById(project_type).selected = true;
      }
    },[]);

    //SET STATUSES OF GET STARTED FORM SUBMITION
    if (feasibility_submitted_at !== null) { 
      sessionStorage.setItem("feasibility_submitted", true);
    }

    if (payment_made_at !== null) { 
      sessionStorage.setItem("payment_made", true);
    }

    //DISABLE GET STARTED FIELDS AND OPEN PAYMENT SECTION IF FEASIBILITY SUBMITTED
    if (feasibility_submitted_at === null && payment_made_at === null) {
      useEffect(() => {
        //currentWizardStep(1);
      },[]);

    } else if (feasibility_submitted_at !== null && payment_made_at === null) { 

        useEffect(() => {
          //disableAllFields();
          //unlockPaymentSection();
          //nextStepButton("hide");
          //checkEmailForTestingMode(email);
          //currentWizardStep(2);
          //formSubmittedSuccessSection("show");
        },[]);
      
    } else if (feasibility_submitted_at !== null && payment_made_at !== null) { 

        useEffect(() => {
          //disableAllFields();
          //unlockPaymentSection();
          //nextStepButton("hide");
          //hideCreditCardSection();
          //showPaymentSuccessSection();
          //heckEmailForTestingMode(email);
          //currentWizardStep(3);
          //formSubmittedSuccessSection("show");
        },[]);
    }
  }
  
  else {

    var request_id = sessionStorage.getItem("builder_request_id");

    useEffect(() => {
      //currentWizardStep(1);
    },[]);

    var first_name = null;
    var last_name = null;
    var phone = null;
    var email = null;
    var address = null;
    var user_type = null;
    var ready_tostart = null;
    var financing = null;
    var project_type = null;
    var message = null;
    var feasibility_submitted_at = null;
    var payment_made_at = null;

  }
  
//////////////////////// ROUTE GET STARTED FORM SUBMITION //////////////////////
function routeSubmitForm(form_type, stage) {

  if (stage == "check_state") {

      var isStateAllowedJSON = isStateAllowed("https://api.checksolarcost.com/v1/parseUSAddress", "POST");
      var isStateAllowedJSON_response = isStateAllowedJSON.responseText;
      var isStateAllowedJSON_response_obj = JSON.parse(isStateAllowedJSON_response);
      var StateName = isStateAllowedJSON_response_obj.StateName
      var originalAddress = document.getElementById("address").value;
      //alert(String(StateName))
      //alert(document.getElementById("address").value)
      if (String(originalAddress).includes("CA") === true || String(originalAddress).includes("UT") === true){

          //alert("Yes")
          if (sessionStorage.getItem("popup_email_form_submitted") == "true" || sessionStorage.getItem("form_type_is_estimate_request" == "true")) {
            //alert("1")
            updateFeasibilityStudyUserProfile(form_type); }
          else {
            //alert("2")
            submitContactForm(form_type); 
          }

      } else { 

        //alert("No") 
        nextStepButton("hide");
        nextStepButtonWrongState("show");
      }
  } else {

    if (sessionStorage.getItem("popup_email_form_submitted") == "true" || sessionStorage.getItem("form_type_is_estimate_request" == "true")) {
      //alert("3")
      updateFeasibilityStudyUserProfile(form_type); }
    else {
      //alert("4")
      submitContactForm(form_type); }
    
  }

}


  //var parser = require('parse-address'); 
  //var parsed = parser.parseLocation('1005 N Gravenstein Highway Sebastopol CA 95472');
  //alert (parsed);


  //const script111 = document.createElement("script");

  //script111.src = "./parse-address.min.js";
  //script111.async = true;

  //document.body.appendChild(script111);


function changeInstallLocation(){

  nextStepButtonWrongState("hide");
  disableAllFields("enable");
  document.getElementById("address").value = "";
  //document.getElementById("address_label").color = "red";
  checkSubmitButton();
  nextStepButton("show");
  nextStepButton("disable");
  scrollToGetStarted();

  return
}


//////////////////////// GET Feasibility Price //////////////////////
function getFeasibilityStudyPrice(address, reqType, asyncProc) {
  var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
  if (asyncProc) { 
    req.onreadystatechange = function() { 
      if (this.readyState == 4) {
        asyncProc(this);
      } 
    };
  }
  typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
  typeof window !== "undefined" ? req.send() : ""
  return req;
  }
  var req_feasibility_price = getFeasibilityStudyPrice("https://api.checksolarcost.com/v1/getFeasibilityStudyPrice", "POST");

//////////////////////// GET Site Prep Cost //////////////////////// 
function getSitePrepCost(address, reqType, customZipCode, asyncProc) {

  if (customZipCode === null || customZipCode === ""){ }
  else { 
    var data = typeof window !== "undefined" ? new FormData() : ""
    typeof window !== "undefined" ? data.append("zip", customZipCode) : ""
  }
  
  var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
  if (asyncProc) { 
    req.onreadystatechange = function() { 
      if (this.readyState == 4) {
        asyncProc(this);
      } 
    };
  }
  typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
  typeof window !== "undefined" ? req.send(data) : ""
  return req;
  }


function calculateEstimateSection(){

  try {
    var customZipCode = document.getElementById("custom_zipcode").value;
  } catch (error) { var customZipCode = null; }
  
  var req_siteprep_cost = getSitePrepCost("https://api.checksolarcost.com/v1/getSitePrepCost", "POST", customZipCode);
  var req_siteprep_cost_txt = req_siteprep_cost.responseText;
  var req_siteprep_cost_obj = JSON.parse(req_siteprep_cost_txt);
  var feasibility_price = formatter.format(req_feasibility_price.responseText);
  var SPDI = req_siteprep_cost_obj.cost;
  var estimate_configuration = formatter.format(modal.cost);
  var estimate_SPDI = formatter.format(SPDI);
  var estimate_total = formatter.format(Number(modal.cost) + Number(SPDI) + Number(req_feasibility_price.responseText));
  var estimate_zip = req_siteprep_cost_obj.zip;
  var estimate_monthly_payment = formatter.format( ( (Number(modal.cost) + Number(SPDI) + Number(req_feasibility_price.responseText)) / 360 ) + ( (Number(modal.cost) + Number(SPDI) + Number(req_feasibility_price.responseText)) * 0.05 / 12 ) );
  
  //alert("estimate_SPDI" + estimate_SPDI);

  document.getElementById('feasibility_price').innerHTML = feasibility_price;
  document.getElementById('feasibility_price_payment').innerHTML = feasibility_price;
  document.getElementById('estimate_configuration').innerHTML = estimate_configuration;
  document.getElementById('estimate_SPDI').innerHTML = estimate_SPDI;
  document.getElementById('estimate_total').innerHTML = estimate_total;
  document.getElementById('custom_zipcode').placeholder=estimate_zip;
  document.getElementById('estimate_monthly_payment').innerHTML = estimate_monthly_payment;

  //return {feasibility_price, estimate_configuration, estimate_SPDI, estimate_total, estimate_zip, estimate_monthly_payment}
  return 
}

useEffect(() => {
  //var {feasibility_price, estimate_configuration, estimate_SPDI, estimate_total, estimate_zip, estimate_monthly_payment} = calculateEstimateSection();
 //calculateEstimateSection();
},[]);



//////////////////////// GOOGLE AUTOCOMPLETE ADDRESS //////////////////////
const { ref } = usePlacesWidget({
  apiKey: "AIzaSyAEUA8nRhSd10_ezuWEMEV5TwELbbuwxf4",
  onPlaceSelected: (place) => {
    console.log(place);
    
    //UPDATE LOCAL VALUE EVERY TIME WHEN INPUT CHANGED
    sessionStorage.setItem('live_formatted_address', JSON.stringify(place.formatted_address));
    checkSubmitButton();

  },
  options: {
    types: ["geocode", "establishment"],
    componentRestrictions: { country: "us" },
  },
});

//////////////////////// ADD WEB EVENT //////////////////////
function addWebEvent(event_type, event_name) {
var data = new FormData();
data.append("event_type", event_type);
data.append("event_name", event_name);

const queryString = typeof window !== "undefined" ? window.location.search : ""
const urlParams = new URLSearchParams(queryString);
var cid = urlParams.get("cid");
data.append("cid", cid);

var xhr = new XMLHttpRequest();
xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
xhr.send(data);
}

useEffect(() => {
  //addWebEvent("visit", "design-builder-feasibility-form")
});

//////////////////////// HUB-SPOT TAG ////////////////////////
useEffect(() => {
  const script = document.createElement('script');
  script.src="//js-na1.hs-scripts.com/23564468.js";
  script.id="hs-script-loader";
  script.async = true;
  document.body.appendChild(script);
},[]);


//////////////////////// CHECK IF THIS GET STARTED FORM ////////////////////////

useEffect(() => {
  if (sessionStorage.getItem("is_get_started") == "true") {
    //alert(sessionStorage.getItem("is_get_started")) 
    estimateSection("hide")
    configurationSavedSection("hide")
  }
},[]);



//////////////////////// CHECK GET STARTED FORM SUBMITTED BUTTON STATUS //////////////////////
function checkSubmitButton(label) {

  var first_name = document.getElementById("first_name").value;
  var last_name = document.getElementById("last_name").value;
  var phone = document.getElementById("phone").value;
  var email = document.getElementById("email").value;
  var address = document.getElementById("address").value;
  var user_type = document.getElementById("user_type").value;

  var ready_tostart = document.getElementById("ready_tostart").value;
  var financing = document.getElementById("financing").value;
  var project_type = document.getElementById("project_type").value;
  var message = document.getElementById("message").value;

  var facebook = document.getElementById("facebook").checked;
  var google = document.getElementById("google").checked;
  var instagram = document.getElementById("instagram").checked;
  var news = document.getElementById("news").checked;
  var friends = document.getElementById("friends").checked;
  var event = document.getElementById("event").checked;
  var houzz = document.getElementById("houzz").checked;
  var podcast = document.getElementById("podcast").checked;
  var other = document.getElementById("other").checked;

  const live_formatted_address = String(sessionStorage.getItem('live_formatted_address')).replace(/["']/g, "");

  if (first_name.length > 2 && last_name.length > 2 && phone.length >= 10 && address.length >= 10 && live_formatted_address.match(/^\d/) !== null && email.includes("@") === true && email.includes(".") === true && user_type !== "true" && ready_tostart !== "true" && financing !== "true" && project_type !== "true" && message !== "true" && (google === true || facebook === true || instagram === true || news === true || friends === true || event === true || houzz === true || podcast === true || other === true)) { 
    nextStepButton("enable");
  } else {
    nextStepButton("disable");
  }

  

  if (label == "next" || sessionStorage.getItem("is_next_button_submitted_for_non_completed_form") === "true") {

    sessionStorage.setItem("is_next_button_submitted_for_non_completed_form", true);


    ///////////////// INPUT CHECKER /////////////////

    //first_name_label_error
    //last_name_label_error
    //phone_label_error
    //email_label_error
    //address_label_error
    //user_type_label_error
    //ready_tostart_label_error
    //project_type_label_error
    //financing_label_error
    //hear_about_us_label_error

    

    document.getElementById("phone_label_error").hidden=false;
    document.getElementById("email_label_error").hidden=false;
    document.getElementById("address_label_error").hidden=false;
    document.getElementById("user_type_label_error").hidden=false;
    document.getElementById("ready_tostart_label_error").hidden=false;
    document.getElementById("financing_label_error").hidden=false;
    document.getElementById("project_type_label_error").hidden=false;
    document.getElementById("hear_about_us_label_error").hidden=false;
  

    if(first_name.length > 2) {document.getElementById("first_name_label_error").hidden=true; }
    else {document.getElementById("first_name_label_error").hidden=false;}

    if(last_name.length > 2) {document.getElementById("last_name_label_error").hidden=true; }
    else {document.getElementById("last_name_label_error").hidden=false;}

    if(phone.length >= 10) {document.getElementById("phone_label_error").hidden=true;}
    else {document.getElementById("phone_label_error").hidden=false;}

    if(email.includes("@") === true && email.includes(".") === true) {document.getElementById("email_label_error").hidden=true; }
    else {document.getElementById("email_label_error").hidden=false;}

    if(address.length >= 10 && live_formatted_address.match(/^\d/) !== null) {document.getElementById("address_label_error").hidden=true;}
    else {document.getElementById("address_label_error").hidden=false;}

    if(user_type !== "true") {document.getElementById("user_type_label_error").hidden=true;}
    else {document.getElementById("user_type_label_error").hidden=false;}

    if(ready_tostart !== "true") {document.getElementById("ready_tostart_label_error").hidden=true;}
    else {document.getElementById("ready_tostart_label_error").hidden=false;}

    if(financing !== "true") {document.getElementById("financing_label_error").hidden=true;}
    else {document.getElementById("financing_label_error").hidden=false;}

    if(project_type !== "true") {document.getElementById("project_type_label_error").hidden=true;}
    else {document.getElementById("project_type_label_error").hidden=false;}

    if(google === true || facebook === true || instagram === true || news === true || friends === true || event === true || houzz === true || podcast === true || other === true) {document.getElementById("hear_about_us_label_error").hidden=true;}
    else {document.getElementById("hear_about_us_label_error").hidden=false;}

  }
}

//useEffect(() => {
//  checkSubmitButton()
//});

function scrollToPayment(){
  const divElement = document.getElementById('payment-section-unlocked');
  divElement.scrollIntoView({ "scroll-behavior": "smooth"});
}

function scrollToGetStarted(){
  const divElement = document.getElementById('get-started-section');
  divElement.scrollIntoView({ "scroll-behavior": "smooth"});
}

function scrollToTop(){
  const divElement = document.getElementById('full_content_section');
  divElement.scrollIntoView({ "scroll-behavior": "smooth"});
}

//scrollToPayment();

function disableAllFields(value) {

    if (value == "email") {

      document.getElementById("email").disabled = true;

    } 
    
    else if (value == "enable") {

      document.getElementById("first_name").disabled = false;
      document.getElementById("last_name").disabled = false;
      document.getElementById("phone").disabled = false;
      document.getElementById("email").disabled = false;
      document.getElementById("address").disabled = false;
      document.getElementById("user_type").disabled = false;
      document.getElementById("ready_tostart").disabled = false;
      document.getElementById("financing").disabled = false;
      document.getElementById("project_type").disabled = false;
      document.getElementById("message").disabled = false;

      document.getElementById("facebook").disabled = false;
      document.getElementById("google").disabled = false;
      document.getElementById("instagram").disabled = false;
      document.getElementById("news").disabled = false;
      document.getElementById("friends").disabled = false;
      document.getElementById("event").disabled = false;
      document.getElementById("houzz").disabled = false;
      document.getElementById("podcast").disabled = false;
      document.getElementById("other").disabled = false;
      document.getElementById("message_other").disabled = false;

      //SHOW LEARN_ABOUT SECTION
      document.getElementById("learn_about_us").hidden = false;

    }
    
    else {

      document.getElementById("first_name").disabled = true;
      document.getElementById("last_name").disabled = true;
      document.getElementById("phone").disabled = true;
      document.getElementById("email").disabled = true;
      document.getElementById("address").disabled = true;
      document.getElementById("user_type").disabled = true;
      document.getElementById("ready_tostart").disabled = true;
      document.getElementById("financing").disabled = true;
      document.getElementById("project_type").disabled = true;
      document.getElementById("message").disabled = true;

      document.getElementById("facebook").disabled = true;
      document.getElementById("google").disabled = true;
      document.getElementById("instagram").disabled = true;
      document.getElementById("news").disabled = true;
      document.getElementById("friends").disabled = true;
      document.getElementById("event").disabled = true;
      document.getElementById("houzz").disabled = true;
      document.getElementById("podcast").disabled = true;
      document.getElementById("other").disabled = true;

      //HIDE LEARN_ABOUT SECTION
      document.getElementById("learn_about_us").hidden = true;

    }

  }

////////////////////// BUTTONS LOGIC //////////////////// 

function checkDesignRequestEmail() {

  var email = document.getElementById("modal-design-request-popup-email-input").value;

  if (email.includes("@") == true && email.includes(".") == true) { 
    document.getElementById("modal-design-request-popup-button-disabled").hidden = true;
    document.getElementById("modal-design-request-popup-button").hidden = false;
  } else {
    document.getElementById("modal-design-request-popup-button-disabled").hidden = false;
    document.getElementById("modal-design-request-popup-button").hidden = true;
  }
}

function checkPaymentButton() { 

  var cc_number_feasibility = document.getElementById("cc_number_feasibility").value;
  var cc_name_feasibility = document.getElementById("cc_name_feasibility").value;
  var cc_month_feasibility = document.getElementById("cc_month_feasibility").value;
  var cc_year_feasibility = document.getElementById("cc_year_feasibility").value;
  var cc_cvv_feasibility = document.getElementById("cc_cvv_feasibility").value;

  //alert(user_type)
  if (cc_number_feasibility.length == 16 && cc_name_feasibility.length >= 6 && cc_month_feasibility !== "true" && cc_year_feasibility !== "true" && cc_cvv_feasibility.length >= 3) { 
    document.getElementById("payment-button-disabled").hidden = true;
    document.getElementById("payment-button").hidden = false;
    document.getElementById("next-step-button-section").className = "hidden";
  } else {
    document.getElementById("payment-button-disabled").hidden = false;
    document.getElementById("payment-button").hidden = true;
    document.getElementById("next-step-button-section").className = "hidden";
  }

}

////////////////////// CHECK IF ADDRESS IS FULL //////////////////// 
const isAddressValid = () => {
  if (/\d/.test(document.getElementById("address").value) == false) {
    document.getElementById("address").value = "";
    document.getElementById("address").placeholder = "Please enter complete address";
  }
};

useEffect(() => {
  document.title = 'Get Started | Modal Homes';
}, []);

//////////////////// DESIGN REQUEST POPUP /////////////////////////

function designRequestPopup(command) {

  if (command === "open") {
    document.getElementById("full_content_section").style['opacity'] = "0.1";
    document.getElementById("modal-design-request-popup").hidden = false;
    scrollToTop();

    //CLOSE POPUP VIA CLICKING OUTSIDE AREA
    var design_popup = document.getElementById("full_content_section");
    design_popup.onclick = function() { designRequestPopup("close"); }

    }

  if (command === "close") {
    document.getElementById("full_content_section").style['opacity'] = "1";
    document.getElementById("modal-design-request-popup").hidden = true;
    }

  }

function financialOptionsRequestPopup(command) {

  if (command === "open") {
    document.getElementById("full_content_section").style['opacity'] = "0.1";
    document.getElementById("modal-financing-options-request-popup").hidden = false;
    scrollToTop();
    addWebEvent("visit", "design-builder-financing-options-clicked");

    //CLOSE POPUP VIA CLICKING OUTSIDE AREA
    var financial_popup = document.getElementById("full_content_section");
    financial_popup.onclick = function() { financialOptionsRequestPopup("close"); }

    }

  if (command === "close") {
    document.getElementById("full_content_section").style['opacity'] = "1";
    document.getElementById("modal-financing-options-request-popup").hidden = true;
    }

  }


//////////////////// API SUBMITIONS //////////////////// 

function submitContactForm(form_type) {

  //HIDE BUTTON AND SHOW SPINNER
  nextStepButton("hide");
  document.getElementById("next-step-spinner").hidden = false;

  //READ DATA FROM INPUT
  //var form_type = "configuration_request";
  var first_name = document.getElementById("first_name").value;
  var last_name = document.getElementById("last_name").value;
  var phone = document.getElementById("phone").value;
  var email = document.getElementById("email").value;

  if (document.getElementById("message_other").value === ""){
    var message = document.getElementById("message").value;
    //alert(message)
  } else {
    var message_original = document.getElementById("message").value;
    var message_other = document.getElementById("message_other").value;
    var message = message_original.concat(" --LearnAboutUsMessage: ", message_other);
    //alert(message)
  }

  var user_type = document.getElementById("user_type").value;
  var ready_tostart = document.getElementById("ready_tostart").value;
  var financing = document.getElementById("financing").value;
  var project_type = document.getElementById("project_type").value;
  var feasibility_submitted_at = new Date().toISOString(); //UTC TIME MUST BE
  var address = document.getElementById("address").value;

  var facebook = document.getElementById("facebook").checked;
  var google = document.getElementById("google").checked;
  var instagram = document.getElementById("instagram").checked;
  var news = document.getElementById("news").checked;
  var friends = document.getElementById("friends").checked;
  var event = document.getElementById("event").checked;
  var houzz = document.getElementById("houzz").checked;
  var podcast = document.getElementById("podcast").checked;
  var other = document.getElementById("other").checked;

  //API CALL
  var data = new FormData();
  data.append("request_id", request_id);
  data.append("form_type", form_type);
  data.append("first_name", first_name);
  data.append("last_name", last_name);
  data.append("email", email);
  data.append("phone", phone);
  data.append("message", message);
  data.append("user_type", user_type); 
  data.append("ready_tostart", ready_tostart);
  data.append("financing", financing);
  data.append("project_type", project_type);
  data.append("feasibility_submitted_at", feasibility_submitted_at);
  data.append("address", String(sessionStorage.getItem('live_formatted_address')).replace(/["']/g, ""));
  data.append("facebook", facebook);
  data.append("google", google);
  data.append("instagram", instagram);
  data.append("news", news);
  data.append("friends", friends);
  data.append("event", event);
  data.append("houzz", houzz);
  data.append("podcast", podcast);
  data.append("other", other);

  var xhr = new XMLHttpRequest();
  xhr.open("POST", "https://api.checksolarcost.com/v1/submitContactForm");
  xhr.send(data);
  xhr.onreadystatechange = function() {
    if (xhr.readyState === XMLHttpRequest.DONE) {
        //READ DATA
        var response = xhr.responseText;
        if (response !== null) { 
          var response_obj = JSON.parse(response);
          if ( response_obj.status == "200") {

            if (form_type == "configuration_waiting_list") {
              disableAllFields();
              currentWizardStep(3);
              nextStepButton("hide");
              nextStepButtonWrongState("hide");
              waitingListSection('show');
          
            } else {
              disableAllFields();
              nextStepButton("hide");
              nextStepButtonWrongState("hide");
              currentWizardStep(2);
              unlockPaymentSection();
              checkEmailForTestingMode(email); 

              //SCROLL TO PAYMENT SECTION
              setTimeout(function() { scrollToPayment(); },1000);
            }

            //HIDE SPINNER
            document.getElementById("next-step-spinner").hidden = true; 

            //SHOW FORM SUCCESS MESSAGE
            formSubmittedSuccessSection("show");

          }
        } else { }
    }
  }
}

function updateFeasibilityStudyUserProfile(form_type) {

  //HIDE BUTTON AND SHOW SPINNER
  nextStepButton("hide");
  document.getElementById("next-step-spinner").hidden = false;

  //READ DATA FROM INPUT
  var first_name = document.getElementById("first_name").value;
  var last_name = document.getElementById("last_name").value;
  var phone = document.getElementById("phone").value;

  if (document.getElementById("message_other").value === ""){
    var message = document.getElementById("message").value;
    //alert(message)
  } else {
    var message_original = document.getElementById("message").value;
    var message_other = document.getElementById("message_other").value;
    var message = message_original.concat(" --LearnAboutUsMessage: ", message_other);
    //alert(message)
  }

  var user_type = document.getElementById("user_type").value;
  var ready_tostart = document.getElementById("ready_tostart").value;
  var financing = document.getElementById("financing").value;
  var project_type = document.getElementById("project_type").value;
  var address = document.getElementById("address").value;

  var facebook = document.getElementById("facebook").checked;
  var google = document.getElementById("google").checked;
  var instagram = document.getElementById("instagram").checked;
  var news = document.getElementById("news").checked;
  var friends = document.getElementById("friends").checked;
  var event = document.getElementById("event").checked;
  var houzz = document.getElementById("houzz").checked;
  var podcast = document.getElementById("podcast").checked;
  var other = document.getElementById("other").checked;

  //API CALL
  var data = new FormData();
  data.append("request_id", request_id);
  data.append("form_type", form_type)
  data.append("first_name", first_name);
  data.append("last_name", last_name);
  data.append("phone", phone);
  data.append("message", message);
  data.append("user_type", user_type); 
  data.append("ready_tostart", ready_tostart);
  data.append("financing", financing);
  data.append("project_type", project_type);
  data.append("address", address);
  data.append("facebook", facebook);
  data.append("google", google);
  data.append("instagram", instagram);
  data.append("news", news);
  data.append("friends", friends);
  data.append("event", event);
  data.append("houzz", houzz);
  data.append("podcast", podcast);
  data.append("other", other);

  var xhr = new XMLHttpRequest();
  xhr.open("POST", "https://api.checksolarcost.com/v1/updateFeasibilityStudyUserProfile");
  xhr.send(data);
  xhr.onreadystatechange = function() {
    if (xhr.readyState === XMLHttpRequest.DONE) {
        //READ DATA
        var response = xhr.responseText;
        if (response !== null) { 
          var response_obj = JSON.parse(response);
          if ( response_obj.status == "200") {

            if (form_type == "configuration_waiting_list") {
              disableAllFields();
              currentWizardStep(3);
              nextStepButton("hide");
              nextStepButtonWrongState("hide");
              waitingListSection('show');
          
            } else {
              disableAllFields();
              nextStepButton("hide");
              currentWizardStep(2);
              unlockPaymentSection();
              checkEmailForTestingMode(email); 

              //SCROLL TO PAYMENT SECTION
              setTimeout(function() { scrollToPayment(); },1000);
            }

            //HIDE SPINNER
            document.getElementById("next-step-spinner").hidden = true; 

            //SHOW FORM SUCCESS MESSAGE
            formSubmittedSuccessSection("show");

          }
        } else { }
    }
  }
}

function submitFeasibilityPayment() {

  //SHOW SPINNER ON START
  document.getElementById("payment-button").hidden = true;
  document.getElementById("payment-spinner").hidden = false;

  //var request_id = sessionStorage.getItem("request_id");
  var user_email = document.getElementById("email").value;
  var cc_number_feasibility = document.getElementById("cc_number_feasibility").value;
  var cc_name_feasibility = document.getElementById("cc_name_feasibility").value;
  var cc_month_feasibility = document.getElementById("cc_month_feasibility").value;
  var cc_year_feasibility = document.getElementById("cc_year_feasibility").value;
  var cc_cvv_feasibility = document.getElementById("cc_cvv_feasibility").value;

  //API CALL
  var data = new FormData();
  data.append("request_id", request_id);
  data.append("user_email", user_email);
  data.append("user_fullname", cc_name_feasibility);
  data.append("number", cc_number_feasibility);
  data.append("exp_month", cc_month_feasibility);
  data.append("exp_year", cc_year_feasibility);
  data.append("cvc", cc_cvv_feasibility);

  var xhr = new XMLHttpRequest();
  xhr.open("POST", "https://api.checksolarcost.com/v1/submitFeasibilityPayment");
  xhr.send(data);
  xhr.onreadystatechange = function() {
    if (xhr.readyState === XMLHttpRequest.DONE) {
        //READ DATA
        var response = xhr.responseText;
        //alert(response);
        if (response !== null) {

          var response_obj = JSON.parse(response);
          if ( response_obj.status == "200" && response_obj.payment_status == "succeeded") {
            
            document.getElementById("payment-spinner").hidden = true;
            hideCreditCardSection();
            showPaymentSuccessSection();
            currentWizardStep(3);

          } else {
            
            document.getElementById("payment-spinner").hidden = true;
            creditCardDeclineMessage("show");
          }

        } else { 
          document.getElementById("payment-spinner").hidden = true;
          creditCardDeclineMessage("show");
        }
    }
  }
}

function submitPopupEmailForm(form_type) {

  //HIDE BUTTON AND SHOW SPINNER
  document.getElementById("modal-design-request-popup-button").hidden = true;
  document.getElementById("modal-design-request-popup-spinner").hidden = false;

  //GET ALL ORDERS IDS
  //var request_id = sessionStorage.getItem("builder_request_id");
  var email = document.getElementById("modal-design-request-popup-email-input").value;
  var data = new FormData();
  data.append("request_id", request_id);
  data.append("form_type", form_type);
  data.append("email", email);
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "https://api.checksolarcost.com/v1/submitContactForm");
  xhr.send(data);
  xhr.onreadystatechange = function() {

    if (xhr.readyState === XMLHttpRequest.DONE) {

        //READ DATA
        var response = xhr.responseText;
        var response_obj = JSON.parse(response);

        if ( response_obj.message == "form_successfully_submitted") {

          document.getElementById("modal-design-request-popup-spinner").hidden = true;
          document.getElementById("modal-design-request-popup-email").hidden = true;
          document.getElementById("modal-design-request-popup-email-sent").hidden = false;

          //DISABLE EMAIL INPUT ONLY
          disableAllFields("email");

          //SET SUBMITTED EMAIL AS VALUE IN FESIBILITY FORM
          document.getElementById("email").value = email;
          checkEmailForTestingMode(email);

          //MARK STATUS OF FORM SUBMITION
          sessionStorage.setItem("popup_email_form_submitted", "true");

        } else {
          document.getElementById("modal-design-request-popup-spinner").hidden = true;
          document.getElementById("modal-design-request-popup-email").hidden = true;
          document.getElementById("modal-design-request-popup-email-error").hidden = true;
        }
    }
  }
}

function checkCustomZipCode(){
  var custom_zipcode = document.getElementById("custom_zipcode").value;
  if (custom_zipcode.length == 5) {
    addWebEvent("visit", "design-builder-zipcode-custom-request");
    calculateEstimateSection();
  }
}

function otherLearnAboutUs() {
  if (document.getElementById("other").checked === true) {
    document.getElementById("message_other_section").hidden = false;
  } else {
    document.getElementById("message_other_section").hidden = true;
  }
}

return (

  <>

  <div id="full_content_section">


    <BuilderExpandingListItem2 startOpen={true} title="Get Started" >
      <section className="text-left font-light text-gray-500 text-xl ">
          We make it easy to get your project started.
          Just fill out the form below. 
          A member of our team will be in touch soon!
      </section>

      <section className="w-full flex flex-col justify-end font-light py-3">
        <div className="">
          <div className="w-full flex justify-between items-start text-left py-4 text-gray-600 outline-none focus:outline-none">
            <div className="w-full">
              <label htmlFor="first_name" className="text-gray-500">
                <font id="first_name_label" color="">
                First Name
                </font>
              </label>
              <input
                //onChange={e => handleUserInputChange(e.target)}
                id="first_name"
                className="my-2 border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none bg-gray-100"
                name="first_name"
                autoComplete="off"
                placeholder={first_name}
                value={first_name}
                onChange={() => checkSubmitButton()}
              />
              <small id="first_name_label_error" style={{"color":"red"}} hidden>Please enter a first name</small>
            </div>
          </div>
        </div>
        <div className="">
          <div className="w-full flex justify-between items-start text-left py-4 text-gray-600 outline-none focus:outline-none bg-gray-100">
            <div className="w-full">
              <label htmlFor="last_name" className="text-gray-500">
                <font id="last_name_label" color="">
                  Last Name
                </font>
              </label>
              <input
                //onChange={e => handleUserInputChange(e.target)}
                id="last_name"
                className="my-2 border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none bg-gray-100"
                name="last_name"
                autoComplete="off"
                placeholder={last_name}
                value={last_name}
                onChange={() => checkSubmitButton()}
              />
              <small id="last_name_label_error" style={{"color":"red"}} hidden>Please enter a last name</small>
            </div>
          </div>
        </div>
        <div className="">
          <div className="w-full flex justify-between items-start text-left py-4 text-gray-600 outline-none focus:outline-none bg-gray-100">
            <div className="w-full">
              <label htmlFor="phone" className="text-gray-500">
                <font id="phone_label" color="">
                  Phone
                </font>
              </label>
              <input
                //onChange={e => handleUserInputChange(e.target)}
                id="phone"
                className="my-2 border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none bg-gray-100"
                type="tel"
                name="phone"
                autoComplete="off"
                placeholder={phone}
                value={phone}
                onChange={() => checkSubmitButton()}
              />
              <small id="phone_label_error" style={{"color":"red"}} hidden>Please enter a valid phone number</small>
            </div>
          </div>
        </div>
        <div className="">
          <div className="w-full flex justify-between items-start text-left py-4 text-gray-600 outline-none focus:outline-none bg-gray-100">
            <div className="w-full">
              <label htmlFor="email" className="text-gray-500">
                <font id="email_label" color="">
                  Email
                </font>
              </label>
              <input
                //onChange={e => handleUserInputChange(e)}
                type="email"
                className="my-2 border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none bg-gray-100"
                id="email"
                name="email"
                placeholder={email}
                autoComplete="off"
                value={email}
                onChange={() => checkSubmitButton()}
              />
              <small id="email_label_error" style={{"color":"red"}} hidden>Please enter a valid email address</small>
            </div>
          </div>
        </div>
       

        





        
       
       
       
        <div className="">
          <div className="w-full flex justify-between items-start text-left py-4 text-gray-600 outline-none focus:outline-none bg-gray-100">
            <div className="w-full">
              <label htmlFor="message" className="text-gray-500 text-sm">
                Additional Comments
              </label>
              <textarea
                onChange={() => checkSubmitButton()}
                rows="2"
                id="message"
                className="my-2 border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none bg-gray-100"
                name="message"
                autoComplete="off"
                placeholder={message}
                value={message}
              ></textarea>
            </div>
          </div>
        </div>
        <div id="learn_about_us" className="">
          <div className="w-full flex justify-between items-start text-left py-4 text-gray-600 outline-none focus:outline-none bg-gray-100">
            <div className="w-full">
              <label htmlFor="hear_about_us" className="text-gray-500 text-sm">
                <font id="hear_about_us_label" color="">
                Where did you learn about us?
                </font>
              </label>
              <br></br>
              <small id="hear_about_us_label_error" style={{"color":"red"}} hidden>Please select an option</small>
            
              <ul className="flex flex-wrap items-start list-none py-4">
                <li>
                  <div className="my-2 text-left" 
                  //onClick={() => checkSubmitButton()}
                  >
                  <div className="input-container">
                      <input className="checkbox-button" type="checkbox" id="facebook" name="facebook"/>
                      <div className="checkbox-tile">
                        <label for="facebook" className="checkbox-tile-label">Facebook</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="my-2 text-left" 
                  //onClick={() => checkSubmitButton()}
                  >
                    <div className="input-container">
                      <input className="checkbox-button" type="checkbox" id="google" name="google"/>
                      <div className="checkbox-tile">
                        <label for="google" className="checkbox-tile-label">Google</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="my-2 text-left" 
                  //onClick={() => checkSubmitButton()}
                  >
                    <div className="input-container">
                      <input className="checkbox-button" type="checkbox" id="instagram" name="instagram"/>
                      <div className="checkbox-tile">
                        <label for="instagram" className="checkbox-tile-label">Instagram</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="my-2 text-left" 
                  //onClick={() => checkSubmitButton()}
                  >
                    <div className="input-container">
                      <input className="checkbox-button" type="checkbox" id="news" name="news"/>
                      <div className="checkbox-tile">
                        <label for="news" className="checkbox-tile-label">News</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="my-2 text-left" 
                  //onClick={() => checkSubmitButton()}
                  >
                    <div className="input-container">
                      <input className="checkbox-button" type="checkbox" id="friends" name="friends"/>
                      <div className="checkbox-tile">
                        <label for="friends" className="checkbox-tile-label">Friends</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="my-2 text-left" 
                  //onClick={() => checkSubmitButton()}
                  >
                    <div className="input-container">
                      <input className="checkbox-button" type="checkbox" id="event" name="event"/>
                      <div className="checkbox-tile">
                        <label for="event" className="checkbox-tile-label">Event</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="my-2 text-left" 
                  //onClick={() => checkSubmitButton()}
                  >
                    <div className="input-container">
                      <input className="checkbox-button" type="checkbox" id="houzz" name="houzz"/>
                      <div className="checkbox-tile">
                        <label for="houzz" className="checkbox-tile-label">Houzz</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="my-2 text-left" 
                  //onClick={() => checkSubmitButton()}
                  >
                    <div className="input-container">
                      <input className="checkbox-button" type="checkbox" id="podcast" name="podcast"/>
                      <div className="checkbox-tile">
                        <label for="podcast" className="checkbox-tile-label">Podcast</label>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="my-2 text-left" 
                  //onClick={() => checkSubmitButton()}
                  >
                    <div className="input-container">
                      <input className="checkbox-button" onClick={() => otherLearnAboutUs()} type="checkbox" id="other" name="other"/>
                      <div className="checkbox-tile">
                        <label for="other" className="checkbox-tile-label">Other</label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <div id="message_other_section" hidden>
                <label htmlFor="message_other" className="text-gray-500 text-sm">
                  Please specify (optional)
                </label>
                <div className="w-full">
                  <textarea
                    //onChange={() => checkSubmitButton()}
                    rows="2"
                    id="message_other"
                    className="my-2 border-b-2 border-gray-300 w-full outline-none placeholder-gray-500 focus:outline-none"
                    name="message_other"
                    autoComplete="off"
                    //placeholder={message}
                    //value=""
                  ></textarea>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div id="form-submitted-success" hidden>
          <div className="flex justify-center pb-10 font-light text-gray-500">
            Form successfully submitted
          </div>
        </div>

        <div id="next-step-button-section">
          <div className="flex justify-center pb-10">
            <div id="next-step-button-disabled">
              <button
                className="relative transition transition-delay-longer bg-gray-500 text-white text-center flex justify-center font-bradford items-center px-8 py-3 mx-4 mt-8 shadow-xl outline-none focus:outline-none disabled"
                //onClick={() => checkSubmitButton("next")}
              >Submit
              </button>
            </div>
            <div id="next-step-button" hidden>
              <button
                //onClick={() => submitContactForm("estimate_request")}
                //onClick={() => routeSubmitForm("configuration_request", "check_state")}
                className="relative transition transition-delay-longer bg-green-900 text-white text-center flex justify-center font-bradford items-center px-8 py-3 mx-4 mt-8 shadow-xl outline-none focus:outline-none"
              >Submit
              </button>
            </div>
          </div>
        </div>

        <div id="next-step-button-section-wrong-state" hidden>
          <div className="flex justify-center pb-10">
          <h5>It looks like you’re out of range</h5>
          </div>
          <div className="flex justify-center pb-5">
            <div className="text-light">
            We currently offer turn-key service in Utah and California. We’re working on new partnerships to 
            deliver Modal to every corner of the United States. 
            If you’d like updates on our serviceability you can add your profile to waiting list.
            </div>
          </div>
          <div className="flex justify-center pb-5">
            <button
              //onClick={() => submitContactForm("estimate_request")}
              //onClick={() => routeSubmitForm("configuration_request", null)}
              onClick={() => changeInstallLocation()}
              className="relative transition transition-delay-longer bg-green-900 text-white text-center flex justify-center font-bradford items-center px-8 py-3 mx-4 mt-8 shadow-xl outline-none focus:outline-none"
            >Change Install Location
            </button>
          </div>
          
          <div className="text-light text-gray-500 flex justify-center">
            - OR - 
          </div>
          <div className="flex justify-center pb-5">
            <button
              onClick={() => routeSubmitForm("configuration_waiting_list", null)}
              className="relative transition transition-delay-longer bg-green-900 text-white text-center flex justify-center font-bradford items-center px-8 py-3 mx-4 mt-8 shadow-xl outline-none focus:outline-none"
            >Add me to waiting list
            </button>
          </div>
        </div>

        <dev id="waiting-list-section" hidden>
          <div className="flex justify-center">
            <h3>Thank you</h3>
          </div>
          <div className="text-center justify-center">
            <h4>Your profile successfully added to waiting list</h4>
          </div>
        </dev>

        <div id="next-step-spinner" hidden>
          <LoadingSpinner />
        </div>
      </section>

    </BuilderExpandingListItem2>


    
  </div>

  <div id="modal-design-request-popup" hidden>
    <section className="absolute top-0 left-0 right-0 z-20 p-6 flex justify-between" >
        <table style={{ "border-collapse":"separate", "border-radius":"6px", "background-color": "#C5C7C5"}} className="px-6 md:px-4 justify-between items-start py-4 text-gray-600 outline-none focus:outline-none">
        
          {//<th colspan="1" style={{ "background-color": "#C5C7C5"}} className="w-full text-xl font-light"></th> 
          //<TargetIcon fill="black" rotate={true}></TargetIcon>}
          }

          <div className="py-2"></div>

          <tr>
            <th colspan="2" style={{ "background-color": "#C5C7C5"}} className="w-full text-xl font-light">
              Get the link to your Modal design
            </th> 
          </tr>

          <div className="py-4"></div>

          <tr>
            <th colspan="2" style={{ "background-color": "#C5C7C5", "text-decoration": "underline"}} className="w-full text-xl font-light">
              <a href={ 'https://www.checksolarcost.com/design-builder?request_id=' + request_id} target="blank">https://checksolarcost.com/design...</a>
            </th> 
          </tr>

          <tr>
            <th colspan="2" style={{ "background-color": "#C5C7C5", "text-decoration": "underline"}} className="w-full font-light">
            <button onClick={() => navigator.clipboard.writeText('https://www.checksolarcost.com/design-builder?request_id=' + request_id)}>Click to copy link</button>
            </th> 
          </tr>
          
          <div id="modal-design-request-popup-email">
            <div className="">
                <div className="w-full mx-auto mt-6 px-6">
                  <div className="w-full my-1">
                    <label htmlFor="modal-design-request-popup-email-input" hidden>
                      Email
                    </label>
                    <input
                      //onChange={e => handleUserInputChange(e.target)}
                      type="email"
                      className="p-2 my-4 text-center bg-transparent border-b-2 border-gray-400 w-full outline-none placeholder-gray-500 focus:outline-none"
                      id="modal-design-request-popup-email-input"
                      name="modal-design-request-popup-email-input"
                      placeholder="Enter Email Address"
                      autoComplete="off"
                      onChange={checkDesignRequestEmail}
                    />
                  </div>
                </div>
                <div className="flex justify-center pb-10">
                  <div id="modal-design-request-popup-button-disabled">
                    <button
                      className={`relative transition transition-delay-longer bg-green-900 text-white text-center flex justify-center font-bradford items-center px-8 py-3 mx-4 mt-8 shadow-xl outline-none focus:outline-none`}
                      disabled
                    >Email my Design
                    </button>
                  </div>
                  <div id="modal-design-request-popup-button" hidden>
                    <button
                      onClick={() => submitPopupEmailForm("configuration_saved_request")}
                      className={`relative transition transition-delay-longer bg-green-900 text-white text-center flex justify-center font-bradford items-center px-8 py-3 mx-4 mt-8 shadow-xl outline-none focus:outline-none`}
                    >Email my Design
                    </button>
                  </div>
                </div>

                <div id="modal-design-request-popup-spinner" className="relative w-full text-center overflow-hidden h-auto" hidden>
                    <LoadingSpinner />
                </div>
 
                <div className="font-light text-center tracking-wide ">
                  The information you provide on this form will be used in accordance
                  with our{" "}
                  <Link className="underline" to="/privacy">
                    privacy policy.
                  </Link>{" "}
                </div>
          
            </div>
          </div>

          <div id="modal-design-request-popup-email-sent" hidden>
            <div className="text-center p-6">
                <h4 className="text-center font-light tracking-wide px-20 md:px-6">
                  Design <span className="font-bradford"> successfully </span> sent
                </h4>
            </div>
          </div>

          <div id="modal-design-request-popup-email-error" hidden>
            <div className="text-center p-6">
                <h5 className="text-center font-light tracking-wide px-20 md:px-6">
                  Internal Error. Please try again later. Sorry for inconvenience.
                </h5>
            </div>
          </div>

          <div className="py-3"></div>

          <tr>
            <th colspan="2" style={{ "background-color": "#C5C7C5"}} className="w-full text-sm font-light" onClick={() => designRequestPopup("close")}>Close window</th> 
          </tr>
      
        </table>
    </section>
  </div>

  <div id="modal-financing-options-request-popup" hidden>
    <section className="absolute top-0 left-0 right-0 z-20 p-6 flex justify-between" >
        <table style={{ "border-collapse":"separate", "border-radius":"6px", "background-color": "#C5C7C5"}} className="px-6 md:px-4 justify-between items-start py-4 text-gray-600 outline-none focus:outline-none">
          <div className="py-2"></div>
          <tr>
            <th colspan="2" style={{ "background-color": "#C5C7C5"}} className="w-full text-xl font-light">
              Modal Financing
            </th> 
          </tr>
          <div className="py-4"></div>
          <tr>
            <th colspan="2" style={{ "background-color": "#C5C7C5"}} className="w-full font-light text-left">
            Modal dwellings can be purchased with a variety of available loan options.
            We are experts in which options exist and we would like to help you identify the best loan for you. We will naturally begin this process together when you start a Feasibility Study.
            </th> 
          </tr>
          <div className="border-b border-gray-500 py-3"></div>
          <div className="py-3"></div>
          <tr>
            <th colspan="2" style={{ "background-color": "#C5C7C5"}} className="w-full font-light text-left">
            If you’re just curious to estimate financing options, please visit the Modal Financing Calculator.
            </th> 
          </tr>

          <tr>
            <th colspan="2" style={{ "background-color": "#C5C7C5"}} className="w-full text-sm font-light">
              <a href="/financial-calculator" target="blank">
              <button className="relative transition transition-delay-longer bg-green-900 text-white text-center justify-center font-bradford items-center px-8 py-3 mx-4 mt-8 shadow-xl outline-none focus:outline-none"
              >Financing Calculator
              </button>
              </a>
            </th>
          </tr>

          <div className="py-3"></div>

          <tr>
            <th colspan="2" style={{ "background-color": "#C5C7C5"}} className="w-full text-sm font-light" onClick={() => financialOptionsRequestPopup("close")}>Close window</th> 
          </tr>
          
        </table>
    </section>
  </div>
  </>
  )
}
