import React, { useEffect, useState } from "react"
import { useQualifyContext } from "../../context/qualify-provider"
import { Type, Field } from "../../state"

export default function QualifyAddress() {
  const [_, dispatch] = useQualifyContext()

  //SET DEFAULT SELECTED UNIT
  useEffect(() => {
    //if (sessionStorage.getItem("builder_selected_unit") === null) {
          //alert("QUALIFY-ADDRESS")
          sessionStorage.setItem("builder_selected_unit", "modal-00");
          //}
  },[]);

  //SKIP THIS STEP
  dispatch({
    type: Type.NextStep,
  })

  return (
<></>
  )
}
