import React from "react"

export default function QualifyStep({ children, theme = "light" }) {
  const themeClass =
    theme === "light" ? "bg-white text-gray-600" : "bg-green-900 text-white"

  return (
    <div
      className={`flex-none w-full h-full flex flex-col px-6 justify-center items-center overflow-y-scroll pt-12 pb-40 ${themeClass}`}
    >
      {children}
    </div>
  )
}
