import React from "react"
import { Link } from "gatsby"

import { useQualifyContext } from "../../context/qualify-provider"
import { Type } from "../../state"
import ArrowSubmit from "../images/arrow-submit"
import TargetIcon from "../images/target-icon"

export default function QualifyHeader() {
  const [state, dispatch] = useQualifyContext()
  const { activeIndex, stages } = state

  const isBuilder = stages[activeIndex] === "builder"
  const isPreview = stages[activeIndex] === "preview"

  function resetModelOptionSelection() {
    //if (sessionStorage.getItem("builder_selected_unit") !== null) {
      //sessionStorage.setItem("builder_selected_unit", "");
      //alert("HERE")
      window.location.replace("/");
    //}
  }

  return (
    
    <>
    <div
      className={`absolute top-0 left-0 right-0 z-20 p-6 flex justify-between z-30 ${
        isBuilder ? "z-30" : "z-30"
      }`}
    >
      <div>
        <button
          className={activeIndex > 0 ? "block focus:outline-none" : "hidden"}
          //onClick={() => {dispatch({ type: Type.PreviousStep }); resetModelOptionSelection();}}
          onClick={isBuilder ? () => {dispatch({ type: Type.PreviousStep }); resetModelOptionSelection();} : event => window.location.href='/design-builder'}
          style={{ transform: "rotate(180deg)" }}
        >
          <ArrowSubmit
            fill={isBuilder ? "white" : "black"}
          ></ArrowSubmit>
        </button>
      </div>

    </div>
    </>
  )
}
