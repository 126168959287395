import React, { useLayoutEffect } from "react"

import { useQualifyContext } from "../../context/qualify-provider"
import { Stage, Type, Field } from "../../state"

import QualifyAddress from "./qualify-address"
import QualifyMessage from "./qualify-message"
import QualifyUse from "./qualify-use"
import QualifyModal from "./qualify-modal"
import QualifyEnd from "./qualify-end"
import QualifyBuilder from "./qualify-builder"
import QualifyPreview from "./qualify-preview"
import QualifyHeader from "./qualify-header"

export default function QualifyStepper({ skipFirstStep, address }) {
  const [state, dispatch] = useQualifyContext()
  const { activeIndex, stages } = state

  const overflowX =
    stages[activeIndex] === Stage.Preview
      ? "overflow-x-scroll h-auto"
      : "overflow-hidden h-screen"

  useLayoutEffect(() => {
    if (skipFirstStep) {
      dispatch({
        payload: { [Field.Address]: address },
      })
      dispatch({
        type: Type.NextStep,
      })
    }
  }, [skipFirstStep])


  const renderStep = stage => {
    switch (stage) {
      case Stage.Address:
        return <QualifyAddress />
      case Stage.Message:
        return <QualifyMessage />
      case Stage.Use:
        return <QualifyUse />
      case Stage.Modal:
        return <QualifyModal />
      case Stage.Builder:
        return <QualifyBuilder />
      case Stage.Sent:
        return <QualifyEnd />
      case Stage.Preview:
        return <QualifyPreview />
    }
  }

  return (
    <div className="">
      <QualifyHeader></QualifyHeader>
      {renderStep(stages[activeIndex])}
    </div>
  )
}
