import React, { useLayoutEffect, useRef, useState, useEffect } from "react"
import { Link } from "gatsby"

import Logo from "../images/logo"
import { useBuilderContext } from "../../context/builder-provider"

export default function BuilderMain() {
  const [state] = useBuilderContext()
  const { variations, selected, lastSelectType } = state
  const variation = variations[lastSelectType]
  const src =
    (variation && variation.image) ||
    (selected.product && selected.product.image)

  //background-color: #f00;//<img src="https://api.checksolarcost.com/v1/getImageFile/jan23_23.png"/>

  //alert(JSON.stringify(state))




  function getSolarPotentialReport(address){

    var data = new FormData();
    data.append("address", address);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/getSolarPotentialReport");
    xhr.send(data);
    xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE) {

          try {
          var response = xhr.responseText;
          var response_obj = JSON.parse(response);
          //alert(response)

          console.log(response)

          document.getElementById("max_panels").innerHTML = response_obj.solarPotential.maxArrayPanelsCount;
          document.getElementById("roof_area").innerHTML = response_obj.solarPotential.wholeRoofStats.areaMeters2;
          document.getElementById("max_instalation_area").innerHTML = response_obj.solarPotential.maxArrayAreaMeters2;
          document.getElementById("max_sunshine_hours_per_year").innerHTML = response_obj.solarPotential.maxSunshineHoursPerYear;
          document.getElementById("carbon_offset_factor").innerHTML = response_obj.solarPotential.carbonOffsetFactorKgPerMwh;

          } catch {

            try {
            document.getElementById("max_panels").innerHTML = 'No data';
            document.getElementById("roof_area").innerHTML = 'No data';
            document.getElementById("max_instalation_area").innerHTML = 'No data';
            document.getElementById("max_sunshine_hours_per_year").innerHTML = 'No data';
            document.getElementById("carbon_offset_factor").innerHTML = 'No data';
            } catch {}

          }
        }
      }
  }








  useEffect(() => {

    

    //const urlParams = new URLSearchParams(window.location.search);
    //var requested_address = urlParams.get("address");

    var requested_address = localStorage.getItem('requested_address');
    //alert(requested_address)

    getSolarPotentialReport(requested_address)

    //alert(requested_address)
    document.getElementById("house_address").innerHTML = requested_address;

  }, [])


  return (
    <>
      
        
       <div className="builder-main-background-image">

       <div className="solar-report-title">Solar-potential report</div>
          <div class="mainBuilder">
          
          <div class="mainBuilderBody">
         
          <div class="mainBuilderRow">
          <div class="mainBuilderCell" style={{"border-right":"1px solid #999999", "width":"280px", "color":"gray"}}>House address</div>
          <div class="mainBuilderCell" id="house_address"></div>
         
          </div>
          <div class="mainBuilderRow">
          <div class="mainBuilderCell" style={{"border-right":"1px solid #999999", "width":"280px", "color":"gray"}}>Max Panels</div>
          <div class="mainBuilderCell" id="max_panels"></div>

          </div>
          <div class="mainBuilderRow">
          <div class="mainBuilderCell" style={{"border-right":"1px solid #999999", "width":"280px", "color":"gray"}}>Roof Area (sq.m)</div>
          <div class="mainBuilderCell" id="roof_area"></div>
  
          </div>
          <div class="mainBuilderRow">
          <div class="mainBuilderCell" style={{"border-right":"1px solid #999999", "width":"280px", "color":"gray"}}>Max Instalation Area (sq.m)</div>
          <div class="mainBuilderCell" id="max_instalation_area"></div>
  
        
          </div>
          <div class="mainBuilderRow">
          <div class="mainBuilderCell" style={{"border-right":"1px solid #999999", "width":"280px", "color":"gray"}}>Max Sunshine Hours Per Year</div>
          <div class="mainBuilderCell" id="max_sunshine_hours_per_year"></div>

          </div>
          <div class="mainBuilderRow">
          <div class="mainBuilderCell" style={{"border-right":"1px solid #999999", "width":"280px", "color":"gray"}}>Carbon Offset Factor (KgPerMwh)</div>
          <div class="mainBuilderCell" id="carbon_offset_factor"></div>

          </div>

          </div>
          </div>
                  
        
        </div>
     
   </>
  )
}
